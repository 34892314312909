import PropTypes from '+prop-types';
import { memo } from 'react';

import styled from 'styled-components';

import CloudOutlineIcon from 'mdi-react/CloudOutlineIcon';

import { AwsKinesisIcon } from './AwsKinesisIcon';
import { AwsS3Icon } from './AwsS3Icon';
import { AwsTgwIcon } from './AwsTgwIcon';
import { AzureIcon } from './AzureIcon';
import { CiscoIcon } from './CiscoIcon';
import { DeviceIcon } from './DeviceIcon';
import { GcpIcon } from './GcpIcon';
import { IbmIcon } from './IbmIcon';
import { OracleIcon } from './OracleIcon';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 0 1px ${({ theme }) => theme.colorFieldBackground});
  margin-top: ${(p) => (p.$deviceMargin ? '2px' : '0')};
`;

const IconMapping = {
  device: DeviceIcon,
  'aws-tgw/s3': AwsTgwIcon,
  'aws/kinesis': AwsKinesisIcon,
  'aws/s3': AwsS3Icon,
  'azure/blobstorage': AzureIcon,
  'azure-vnet/blobstorage': AzureIcon,
  'gcp/pubsub': GcpIcon,
  'ibm/objectstorage': IbmIcon,
  'oracle/objectstorage': OracleIcon,
  'cisco/s3': CiscoIcon,
};

// trafficType prop expects either a SOURCETYPES[value] or 'device'
const VpcIcon = memo(({ sourceType, size }) => {
  const Icon = IconMapping[sourceType];
  return Icon ? (
    <IconWrapper $deviceMargin={sourceType === 'device'}>
      <Icon size={size} />
    </IconWrapper>
  ) : (
    <CloudOutlineIcon size={size} />
  );
});

VpcIcon.propTypes = {
  sourceType: PropTypes.string,
  size: PropTypes.number,
};

VpcIcon.defaultProps = {
  size: 24,
  sourceType: null,
};

export { VpcIcon };
