import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PermissionModel from '@/models/Permission';

import {
  actions as contextIntegrationsActions,
  selectors as contextIntegrationsSelectors,
} from '@/redux/api/integrations/context';

import Button, { ButtonVariants } from '+components/Button';
import { useFormState } from '+components/form/FinalForm';
import Field from '+components/form/FinalForm/Field';
import { Group, Label } from '+components/form/FormField';
import Plaintext from '+components/form/Plaintext';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import { validateRequired } from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import { PluginLabel } from '+components/Labels/PluginLabel';
import { ActionsContainer } from '+components/Layout';
import * as toast from '+components/toast';
import useEvent from '+hooks/useEvent';
import usePermissions from '+hooks/usePermissions';

import FieldsFromManifest from '../shared/fields/context/FieldsFromManifest';
import NoData from '../shared/NoData';
import IntegrationTestModal from './components/IntegrationTestModal';

// don't care about crowdstrike, ibm, and S3 integrations because they don't have tags, names match names from ./plugins
const namesOfPluginsWithTags = ['aws', 'gcp', 'azure', 'oracle'];

const parseNetofuseIntegrationName = (value) => {
  if (typeof value === 'string') {
    return value.toLocaleLowerCase();
  }
  return value;
};

const getAllIntegrationTags = (integrations) => {
  return Object.values(integrations).flatMap((integration) =>
    namesOfPluginsWithTags.flatMap(
      (pluginName) => integration?.[pluginName]?.tags ?? [],
    ),
  );
};

const ModelProps = PropTypes.shape({
  group: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.children,
  minInterval: PropTypes.number,
  manifest: PropTypes.shape(),
});

const FormBody = (props) => {
  const { mode, model, disabled, disableSubmit, formValueChangeCallback } =
    props;

  const dispatch = useDispatch();
  const permissions = usePermissions(
    PermissionModel.Resources.integration.value,
  );

  const { values: formValues, errors: formErrors } = useFormState({
    subscription: { values: true, errors: true },
  });
  const isDynamicIntegration = useMemo(
    () => !!props.model.manifest,
    [props.model.manifest],
  );
  const [isViewingTestResults, setIsViewingTestResults] = useState(false);
  const testResults = useSelector(contextIntegrationsSelectors.getTestResults);

  const Fields = useMemo(
    () => model.component ?? FieldsFromManifest,
    [model.component, formValues],
  );

  const integrations = useSelector(
    contextIntegrationsSelectors.getContextIntegrations,
  );

  const tags = useMemo(
    () => getAllIntegrationTags(integrations),
    [integrations],
  );

  useEffect(() => formValueChangeCallback(formValues), [formValues]);

  const onButtonClick = useEvent((action) => {
    if (Object.keys(formErrors).length) {
      toast.info(
        `Please ensure all required fields are provided before ${
          action === 'run' ? 'running' : 'testing'
        } the integration`,
      );
    } else {
      dispatch(
        action === 'run'
          ? contextIntegrationsActions.runContextIntegration(formValues)
          : contextIntegrationsActions.testContextIntegration(formValues),
      );
    }
  });

  const onTestResultsConfirm = useCallback(() => {
    dispatch(contextIntegrationsActions.testContextIntegrationClear());
    setIsViewingTestResults(false);
  }, []);

  useEffect(() => {
    if (testResults) {
      setIsViewingTestResults(true);
    }
  }, [testResults]);

  return (
    <Fragment>
      {mode === 'edit' && (
        <ActionsContainer>
          <Button
            onClick={() => onButtonClick('run')}
            // Dynamic integrations aren't run or tested from portal until backend work is done to support
            disabled={!formValues.enabled || isDynamicIntegration}
          >
            Run Now
          </Button>
          <Button
            variant={ButtonVariants.outlined}
            onClick={() => onButtonClick('edit')}
            disabled={isDynamicIntegration || !permissions?.create}
          >
            Test Integration
          </Button>
        </ActionsContainer>
      )}
      <Group>
        <Label>Provider</Label>
        <Plaintext>
          <PluginLabel
            adapter={model.name}
            title={model.title}
            logo={model?.manifest?.logo}
            size={14}
          />
        </Plaintext>
      </Group>

      <Field
        name="name"
        label="Name"
        helperText={
          <Fragment>
            Unique name of the context integration. Valid characters are{' '}
            <code>0-9a-z{model?.manifest ? '' : 'A-Z'}._-</code>
          </Fragment>
        }
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        style={{ width: '50%' }}
        disabled={disabled}
        required
        parse={model?.manifest ? parseNetofuseIntegrationName : undefined}
      />

      <Field
        name="updateinterval"
        label="Update Interval"
        helperText="How often labels will be updated"
        component={ToggleField}
        type="checkbox"
        uncheckedLabel="Hourly"
        checkedLabel="Daily"
        disabled={disabled}
        parse={(value) => (value ? 86400 : 3600)}
        format={(value) => value >= 86400}
        twoOptionToggle
      />

      <Field
        name="enabled"
        label="Auto Update"
        component={ToggleField}
        type="checkbox"
        checkedLabel="Enabled"
        disabled={disabled}
      />

      <Fields
        mode={mode}
        disableSubmit={disableSubmit}
        disabled={disabled}
        tags={tags}
        manifest={model?.manifest ?? {}}
      />

      {isViewingTestResults && (
        <IntegrationTestModal
          onConfirm={onTestResultsConfirm}
          onClose={onTestResultsConfirm}
          titleText="Test Successful"
          data={testResults}
          isOpen
          summary="The following labels will be created upon a successful run"
        />
      )}
    </Fragment>
  );
};

FormBody.propTypes = {
  mode: PropTypes.string.isRequired,
  model: ModelProps.isRequired,
  disabled: PropTypes.bool.isRequired,
  disableSubmit: PropTypes.func.isRequired,
  formValueChangeCallback: PropTypes.func.isRequired,
};

const Form = (props) => {
  const {
    mode,
    model,
    initialValues,
    isFetching,
    confirmButtonText,
    deleteButtonText,
    deleteButtonHidden,
    onSubmit,
    onCancel,
    onDelete,
    formValueChangeCallback,
  } = props;

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const permissions = usePermissions(
    PermissionModel.Resources.integration.value,
  );
  const canManage = mode === 'add' ? permissions?.create : permissions?.update;
  const canRemove = mode !== 'add' && permissions?.delete;
  const disabled = !canManage || isFetching || submitDisabled;

  const doSubmit = useCallback(
    (values) => {
      setSubmitDisabled(false);
      onSubmit(values);
    },
    [onSubmit],
  );

  const doCancel = useCallback(() => {
    setSubmitDisabled(false);
    onCancel();
  }, [onCancel]);

  return !model.component && !model.manifest ? (
    <NoData>No Plugin Form Component Found</NoData>
  ) : (
    <FormWizard
      item={model.name}
      initialValues={initialValues}
      loading={isFetching}
      disabled={disabled}
      confirmButtonText={confirmButtonText}
      deleteButtonText={deleteButtonText}
      onDelete={onDelete}
      deleteButtonHidden={deleteButtonHidden}
      deleteButtonDisabled={!canRemove || isFetching}
      onSubmit={doSubmit}
      onCancel={doCancel}
    >
      <Step>
        <FormBody
          mode={mode}
          model={model}
          disabled={disabled}
          disableSubmit={setSubmitDisabled}
          formValueChangeCallback={formValueChangeCallback}
        />
      </Step>
    </FormWizard>
  );
};

Form.propTypes = {
  mode: PropTypes.string.isRequired,
  model: ModelProps.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  deleteButtonText: PropTypes.string,
  deleteButtonHidden: PropTypes.bool,
  onDelete: PropTypes.func,
  formValueChangeCallback: PropTypes.func,
};

Form.defaultProps = {
  deleteButtonText: null,
  deleteButtonHidden: true,
  onDelete: null,
  formValueChangeCallback: () => {},
};

export default Form;
