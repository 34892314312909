import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import RoutePaths from '@/models/RoutePaths';

import Button from '+components/Button';
import { useRemoveLoader } from '+hooks/useRemoveLoader';

const instruction = 'Click Sign In to be directed to the login page.';

const Logout = styled(({ className }) => {
  const { search } = useLocation();

  const onReturnClick = () => {
    const query = new URLSearchParams(search);
    const returnTo = query.get('returnTo');
    if (returnTo) {
      window.location.href = returnTo;
    } else {
      document.location = `${RoutePaths.home}`;
    }
  };

  useRemoveLoader();

  // Page structure same as keycloak login page
  return (
    <div className={className}>
      <div className="login-pf-page-header">
        <div id="kc-header-wrapper">Netography</div>
      </div>
      <div className="card-pf">
        <header className="login-pf-header">
          <h1 id="kc-page-title">You have been logged out</h1>
        </header>
        <div id="kc-content">
          <div id="kc-content-wrapper">
            <div id="kc-info-message">
              <p className="instruction">{instruction}</p>
            </div>
            <Button onClick={onReturnClick}>Sign In</Button>
          </div>
        </div>
      </div>
    </div>
  );
})`
  --textColor: ${({ theme }) => theme.colorText};
  --textSecondaryColor: ${({ theme }) => theme.colorTextSecondary};
  --trademarkColor: #8c8c8c;
  --bodyBackgroundColor: ${({ theme }) => theme.colorBackgroundBody};
  --formBackgroundColor: ${({ theme }) => theme.colorBackground};

  height: 100vh;
  padding-top: 40px;

  background: var(--bodyBackgroundColor);
  border: unset;
  color: var(--textColor);
  font-size: 13px;

  font-family: 'Source Sans Pro', sans-serif;

  .login-pf-page-header {
    position: relative;
    padding-bottom: 55px;
  }

  #kc-header-wrapper {
    background-image: url(/images/logos/logo_light_v20250121.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 75px;
    margin: 32px auto -30px;
    color: transparent;
  }

  .theme-dark & #kc-header-wrapper {
    background-image: url(/images/logos/logo_dark_v20250121.png);
  }

  .card-pf {
    position: relative;
    margin: 0 auto;
    box-shadow:
      0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16),
      0 0 0.375rem 0 rgba(3, 3, 3, 0.08);
    padding: 0 20px;
    max-width: 500px;
    width: 100%;
    background-color: var(--formBackgroundColor);
    border: unset;
    border-radius: 5px;
  }

  .card-pf:after {
    content: 'Get a Holistic View of All Network Activity. Today\\ANetography Fusion\\00ae';
    position: absolute;
    bottom: -120px;
    right: 50%;
    transform: translateX(50%);
    font-size: 18px;
    font-weight: 400;
    color: var(--textColor);
    width: 100%;
    text-align: center;
    white-space: pre-wrap;
    text-shadow: ${({ theme }) =>
      theme.name === 'dark' ? '2px 2px 3px black' : null};
  }

  .login-pf-header {
    display: flex;
    flex-direction: column;
  }

  .login-pf-header h1 {
    text-align: center;
    font-weight: 300;
    color: var(--textColor);
    line-height: 1.1;
    margin: 20px 0 10px 0;
  }

  .instruction {
    color: var(--textSecondaryColor);
  }

  .MuiButton-root {
    margin-top: 40px;
    width: 100%;
    font-size: 14px;
  }

  @media (min-width: 768px) {
    .login-pf-page-header {
      margin-bottom: 40px;
    }
    .card-pf {
      padding: 20px 40px 30px 40px;
    }
    .login-pf-header {
      margin-bottom: 10px;
    }
    .login-pf-header h1 {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    .login-pf-page-header {
      padding-bottom: 20px;
    }

    .card-pf {
      width: 100%;
      max-width: unset;
      margin-left: unset !important;
      margin-right: unset !important;
    }

    .login-pf-page-header:after,
    .card-pf:after {
      display: none;
    }
  }
`;

export default Logout;
