import { VpcIcon } from '@/shared/img/icon';

export const SourceTypeKeys = {
  device: 'device',
  awsKinesis: 'aws/kinesis',
  awsS3: 'aws/s3',
  awsTgw: 'aws-tgw/s3',
  azure: 'azure/blobstorage',
  azureVnet: 'azure-vnet/blobstorage',
  cisco: 'cisco/s3',
  gcp: 'gcp/pubsub',
  ibm: 'ibm/objectstorage',
  oracle: 'oracle/objectstorage',
};

export const SOURCETYPES = {
  [SourceTypeKeys.device]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.device} size={18} />,
    label: 'Device',
  },
  [SourceTypeKeys.awsKinesis]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.awsKinesis} size={18} />,
    label: 'AWS Kinesis',
    flowtype: 'aws',
    flowresource: 'kinesis',
  },
  [SourceTypeKeys.awsS3]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.awsS3} size={18} />,
    label: 'AWS S3 VPC',
    flowtype: 'aws',
    flowresource: 's3',
  },
  [SourceTypeKeys.awsTgw]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.awsTgw} size={18} />,
    label: 'AWS S3 Transit Gateway',
    flowtype: 'aws-tgw',
    flowresource: 's3',
  },
  [SourceTypeKeys.azure]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.azure} size={18} />,
    label: 'Azure NSG',
    flowtype: 'azure',
    flowresource: 'blobstorage',
  },
  [SourceTypeKeys.azureVnet]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.azureVnet} size={18} />,
    label: 'Azure VNet',
    flowtype: 'azure-vnet',
    flowresource: 'blobstorage',
  },
  [SourceTypeKeys.cisco]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.cisco} size={18} />,
    label: 'Cisco Umbrella',
    flowtype: 'cisco',
    flowresource: 's3',
  },
  [SourceTypeKeys.gcp]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.gcp} size={18} />,
    label: 'GCP',
    flowtype: 'gcp',
    flowresource: 'pubsub',
  },
  [SourceTypeKeys.ibm]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.ibm} size={18} />,
    label: 'IBM COS',
    flowtype: 'ibm',
    flowresource: 'objectstorage',
  },
  [SourceTypeKeys.oracle]: {
    icon: <VpcIcon sourceType={SourceTypeKeys.oracle} size={18} />,
    label: 'Oracle COS',
    flowtype: 'oracle',
    flowresource: 'objectstorage',
  },
};
